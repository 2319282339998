// Entry point for the build script in your package.json
// Import all your JS dependencies here
 // app/javascript/packs/application.js
 import 'bootstrap';
 import A11yDialog from 'a11y-dialog';
 import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
 import "@hotwired/turbo-rails";

 import "./controllers"
 import Dropzone from "dropzone";
 import JSConfetti from 'js-confetti'
 import Rails from '@rails/ujs';

 // This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

Rails.start();
require('@rails/activestorage').start();

window.hideAlerts = function() {
    document.querySelectorAll('.alert').forEach(el => {
        if (el.classList.contains('alertOut')) return;

        el.classList.add('alertOut');
        el.addEventListener('animationend', function() {
            el.classList.add('d-none');
        }, { once: true });
    });
}

function autoHideAlerts() {
    setTimeout(hideAlerts, 5000);
}

document.addEventListener(
    'turbo:load',
    () => {
        // Add confetti
        if (!!document.querySelector('[data-confetti]')) {
            const confetti = new JSConfetti();
            confetti.addConfetti();
            confetti.addConfetti();
        }

        // Dropzone init
        if (!!document.getElementById('myDropzone')) {
            new Dropzone('#myDropzone');
        }

        // Hide alerts
        autoHideAlerts();

        // Forms
        document.querySelectorAll('.autosubmit, .inputsave input[type="file"]').forEach(el => { // TODO: change selector to `data-attribute` & see if couldn't add the `data-attribute` directly to the file input
            el.addEventListener('change', function (event) {
                event.target.closest('form')?.submit();
            });
        });

        // Modals auto open
        document.querySelectorAll('[data-a11y-dialog-auto-open]').forEach(modal => {
            window.openModal(modal.dataset.a11yDialogId);
        });
    },
    {
        once: false
    }
);

let scrollTop = 0;

// TODO: Remove this when Turbo supports scroll persistence
document.addEventListener(
    'turbo:render',
    () => {
        Turbo.navigator.currentVisit.scrolled = true; // Prevent scroll restoration on render
        if (scrollTop) {
            document.querySelectorAll('.turbo-disable-scroll-div').forEach(element => {
                element.scrollTop = scrollTop;
            });
            window.scrollTo(0, scrollTop);
        }

        scrollTop = 0;
    }
);

document.addEventListener('click', (event) => {
    if (!event.target.closest('.turbo-disable-scroll')) return;

    scrollTop = document.querySelector('.turbo-disable-scroll-div')?.scrollTop || document.scrollingElement?.scrollTop || 0;
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


/* Modal */

const a11yDialogInstances = {};

function createModalListeners(modalInstance) {
    // Handle body scroll, see: https://a11y-dialog.netlify.app/advanced/scroll-lock/
    modalInstance.on('show', (modal) => disableBodyScroll(modal));
    modalInstance.on('hide', (modal) => enableBodyScroll(modal));

    /* This code is disabled for now because it behaved weirdly, and is too specific to bother with for now
    // Handle `aria-hidden`, see: https://a11y-dialog.netlify.app/further-reading/known-issues#mobile-issues
    let reshowEventRegistered = false;
    modalInstance.on('show', function (modal, event) {
        const reshowOthers = hideOthers(modal);

        if (!reshowEventRegistered) {
            modalInstance.on('hide', reshowOthers);
            reshowEventRegistered = true;
        }
    });
    */

    // Handle closing actions
    modalInstance.on('hide', function(modal, event) {
        function getOwnForms() {
            return [...modal.querySelectorAll('form')].filter(i => i.closest('[data-a11y-dialog-id]') === modal);
        }

        modal.dataset.a11yDialogClosingActions.split(' ').filter(i => !!i).forEach(action => {
            switch (action) {
                case 'reload':
                    Turbo.visit(location.href, { action: "replace" });
                    break;
                case 'submitForms':
                    getOwnForms().forEach(form => {
                        form.submit();
                    });
                    break;
                case 'turboSubmitForms':
                    getOwnForms().forEach(form => {
                        Turbo.navigator.submitForm(form);
                    });
                    break;
                case 'resetForms':
                    getOwnForms().forEach(form => {
                        form.reset();
                    });
                    break;
                default:
                    break;
            }
        });
    });
}

function createModal(modalId) {
    const modal = document.querySelector(`[data-a11y-dialog-id="${modalId}"]`);

    if (modal.parentElement !== document.body) document.body.appendChild(modal); // Move modal DOM to `<body>` to avoid its `<form>`s to interact with `<turbo-frame>`s?

    const modalInstance = new A11yDialog(modal);
    a11yDialogInstances[modalId] = modalInstance;

    createModalListeners(modalInstance);

    return modalInstance;
}

function getModalInstance(modalId) {
    let modalInstance = a11yDialogInstances[modalId];

    if (!modalInstance || !document.body.contains(modalInstance.$el)) { // Modal element reference can be deleted after `Turbo.visit` because it regenerates the DOM
        modalInstance = createModal(modalId);
    }

    return modalInstance;
};

window.openModal = function(modalId) {
    const modalInstance = getModalInstance(modalId);

    modalInstance.show();
};
window.closeModal = function(modalId) {
    const modalInstance = getModalInstance(modalId);

    modalInstance.hide();
};

window.revealDocEditForm = function(docFormId, docLinkId) {
    document.getElementById(docFormId).classList.remove('d-none');
    document.getElementById(docLinkId).classList.add('d-none');
};

window.openBurgerMenu = function(id) {
    document.querySelector(`[data-burger-menu-id="${id}"]`).classList.add('active');
}

window.closeBurgerMenu = function(id) {
    document.querySelector(`[data-burger-menu-id="${id}"]`).classList.remove('active');
}

addEventListener("DOMContentLoaded", () => {
    autoHideAlerts();
});
