import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  // define our targets in our controller
  static targets = ["copyIcon", "checkIcon"];

  static values = {
    text: String,
    redirectionLink: String,
  };

  // define our copy action
  async copy() {
    await copyToClipboard(this.textValue);

    if (this.hasRedirectionLinkValue) {
      window.open(this.redirectionLinkValue, '_blank');
    }

    if (this.hasCheckIconTarget && this.hasCopyIconTarget) {
      this.checkIconTarget.classList.remove('d-none');
      this.copyIconTarget.classList.add('d-none');

      setTimeout(() => {
        this.checkIconTarget.classList.add('d-none');
        this.copyIconTarget.classList.remove('d-none');
      }, 2000)
    }

    async function copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
    }
  }
}
